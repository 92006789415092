<template>

  <div class="filter">
    <div :class="['filter-head', filterBtnBack ? 'back': '']">
      <button v-if="filterBtnBack" @click="goBack" class="no-btn">
        <span class="material-icons icon show-rtl" aria-hidden="true">navigate_next</span>
        <span class="material-icons icon show-ltr" aria-hidden="true">navigate_before</span>
        <h5>{{ filterHead }}</h5>
      </button>
      <h5 v-else>{{ filterHead }}</h5> 
      <button v-if="!filterBtnBack" class="btn btn-link" @click="clearFilter">{{$t('controls.reset-filter')}}</button>
    </div>

    <div v-if="!filterBtnBack && showActiveFilters" class="filter-selected">
      <p v-if="filtersToDisplay.length == 0">keine Filter gesetzt</p>
      <ul class="filter-selected-list">
        <li class="filter-selected-list--item" v-for="(item,index) in filtersToDisplay" :key="index">
          {{item.value}}
          <button @click="removeFilter(item.key,item.value)" class="btn btn-link">&times;</button>
        </li>
      </ul>
    </div>

    <!-- Filter Options -->
    <div v-if="selectedFilterOption">
      <ContentFilterOptions class="mb20" :showCheckBoxAndLabel="false" :level="parseInt(0)" :filter="selectedFilterOption" :selectedFilters="tempFilters" 
      @parentSelectionChanged="selectionChanged" @selectionChanged="selectionChanged"/>
    </div>

    <ul v-else class="filter-list mt20" tabindex="-1">
      <li class="filter-list-item" v-for="(item, index) in filters" tabindex="0" @keyup.enter="filterOption(index,item)" @keyup.space="filterOption(index,item)" @click="filterOption(index,item)" :key="index">
        <h5>{{ getLabel(item.label) }} <span class="number-of-results" v-if="getNumberOfSelectedItems(item)>0">{{ getNumberOfSelectedItems(item) }}</span></h5>
        <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
        <span class="material-icons-outlined show-rtl" aria-hidden="true">navigate_before</span> 
      </li>

    </ul>

    <div class="filter-bottom">
      <button class="btn ml-auto px-5" @click="filteringFinished">{{$t('modals.show-results')}}</button>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'ContentFilters',
    components: {
      ContentFilterOptions: () => import('./ContentFilterOptions'),
      ToggleSwitch: () =>import('@/components/controls/ToggleSwitch')
    },
    props: {
      showActiveFilters: {
        type: Boolean,
      default: false,
      },
      showToggleFilters: {
        type: Boolean,
      default: false,
      },
      filters: {
        type: Array,
        required: false,
      default: [],
      },
      selectedFilters: {
        type: Map,
      default: new Map(),
      },
      contentCount: {
        tyle: Number,
      default: 0
      }
    },
    data() {
      return {
        tempFilters: new Map,
        selectedFilterOption: null,
        filterSelected: [],
        filtersToDisplay: [],
        setFilters: [],
        filterHead: 'FILTER',
        filterBtnBack: false,
        awaitingFilterSelectionFinished: false,
      }
    },
    watch: {
      filterSelected(vals) {
        this.$emit('filters-changed', vals);
      }
    },
    mounted() {
      /*fill the tempFilters with the values of the "real" Filter */
      this.tempFilters = new Map([...this.tempFilters,...this.selectedFilters]);
      this.getSelectedFiltersToDisplay();
    },
    computed: {
      fieldName(){
        if(this.name !== null && this.name !== ""){
          return this.name.replace('Kiel.RefugeeCompass - ','');
        }
        return this.name;
      },
      kinderbetreuungFieldType(){
        let lang = this.$store.getters.getLanguage;
        switch(lang){
        case 'de':
          return 81;
          break;
        case 'en':
          return 82;
          break;
        case 'ar':
          return 186;
          break;
        case 'tr':
          return 83;
          break;
        case 'uk':
          return 322;
          break;
        }
      },
      barrierefreiheitFieldType(){
        let lang = this.$store.getters.getLanguage;
        switch(lang){
        case 'de':
          return 84;
          break;
        case 'en':
          return 85;
          break;
        case 'ar':
          return 187;
          break;
        case 'tr':
          return 86;
          break;
        case 'uk':
          return 323;
          break;
        }
      },
    },
    methods: {
      getLabel(label){
        if(label !== null && label !== ""){
          return label.replace('Kiel.RefugeeCompass - ','');
        }
        return label;
      },
      sliderChanged(fieldTypeId,value){
        this.expandFilter = false;
        if(value){
          this.tempFilters.set(fieldTypeId, ["Kostenlos"]);
        }
        else {
          this.tempFilters.delete(fieldTypeId);
        }
        this.expandFilter = true;
      },
      barrierefreiheitChanged(fieldTypeId,value){
        this.expandFilter = false;
        if(value){
          this.tempFilters.set(fieldTypeId, ["1"]);
        }
        else {
          this.tempFilters.delete(fieldTypeId);
        }
        this.expandFilter = true;
      },
      kinderbetreuungChanged(fieldTypeId,value){
        this.expandFilter = false;
        if(value){
          this.tempFilters.set(fieldTypeId, ["1"]);
        }
        else {
          this.tempFilters.delete(fieldTypeId);
        }
        this.expandFilter = true;
      },
      setupFilter() {
        this.setFilters = this.filterSelected;
        this.filterHead = 'FILTER';
        this.filterBtnBack = false;
        this.selectedFilterOption = null;
        this.filter = false;
      },
      filterOption(index, item) {
        this.filterBtnBack = true;
        this.filterHead = this.getLabel(this.filters[index].label);
        this.selectedFilterOption = item;
      },
      checkIfExist(value) {
        return this.filterSelected.indexOf(value) >= 0 ? true : false;
      },
      choosenFilter(event) {
        if (event.target.checked) {
          this.filterSelected.push(event.target.value);
        } else {
          let index = this.filterSelected.indexOf(event.target.value);
          this.filterSelected.splice(index, 1);
        }
      },
      removeFilter(key,value) {
        var item = this.tempFilters.get(key);
        let index = item.indexOf(value);
        item.splice(index, 1);
        this.getSelectedFiltersToDisplay();
        this.$emit('getNumberOfResults',this.tempFilters);
      },
      clearFilter() {
        this.tempFilters = new Map;
        this.filterSelected = [];
        this.getSelectedFiltersToDisplay();
        this.$emit('resetAllFilters');
      },
      goBack() {
        this.filterHead = 'FILTER';
        this.filterBtnBack = false;
        this.selectedFilterOption = null;
      },
      onClose() {
        this.$emit('onClose',this.selectedFilters);
      },
      filteringFinished(){
        this.$emit('filteringFinished',this.tempFilters);
        this.setupFilter();
      },
      selectionChanged(fieldTypeId,values){
        if(values!==null && values.length>0){
          this.tempFilters.set(fieldTypeId,values);
        }
        else{
          this.tempFilters.delete(fieldTypeId);
        }

        /*let's delay this so we don't get swamped by requests*/
        if (!this.awaitingFilterSelectionFinished) {
          setTimeout(() => {
            this.$emit('getNumberOfResults',this.tempFilters);
            this.awaitingFilterSelectionFinished = false;
          }, 2000); // 2 sec delay
        }
        this.getSelectedFiltersToDisplay();
        this.awaitingFilterSelectionFinished = true;
      },
      getNumberOfSelectedItems(item){
        var number = 0;

        var results = this.tempFilters.get(item.field_type_id);
        if(results!==null && results !== undefined){
          number = results.length;
        }

        if(item.type=="depender" && item.options.length>0){
          for(var i = 0; i<item.options.length;i++){
            number = number+this.getNumberOfSelectedItems(item.options[i]);
          }
        }
        return number;
      },
      getSelectedFiltersToDisplay(){
        var res = [];
        for (const [key, value] of this.tempFilters.entries()) {
          if(Array.isArray(value)){
           for(var i=0; i<value.length;i++){
             res.push({
              key: key,
              value: value[i]
            });
           } 
         }
         else{
          res.push({
            key: key,
            value: value
          });
        }
      }
      this.filtersToDisplay = res;
    },
  }
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .number-of-results{
    background-color: rgb(200, 212, 0);
    border-radius: 50%;
    padding: 4px 9px;
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
  }

  .switch{
    text-transform: uppercase;
    font-size: 14px; 
    color: #3A3A3A;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    font-weight: 600;
  }

  .filter {
    position: absolute;
    z-index: inherit;
    padding: 15px;
    padding-bottom: 0;
    background: $white-color;
    width: 100vw;
    height: calc(100vh - #{$title-bar-size} - #{$mobile-menu-size});
    top: $title-bar-size;
    z-index: inherit;
    display: flex;
    flex-direction: column;

    &-choose {

      height: calc(100vh - #{$title-bar-size} - 24px - 76px - 50px);
      overflow-y: auto;
      @include hide-scrollbar;

      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 25px;

        &.info {
          align-items: flex-start;
          flex-direction: column;

          p {
            margin-left: 40px;
            margin-top: 5px;
            font-size: 12px;
            color: $text-light;
          }
        }

        &.has-options {
          &::after {
            position: absolute;
            font-family: 'Material Icons';
            font-size: 24px;
            content: '\e5cf';
            top: -7px;
            right: 0;
          }
        }

        label {
          color: $black-color;
        }

        &-submenu {
          margin-top: 25px;
          margin-left: 40px;

          &-item {
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-list {

      height: calc(100vh - #{$title-bar-size} - 24px - 76px - 50px);
      overflow-y: auto;
      @include hide-scrollbar;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        margin-bottom: 10px;

        @media (max-width: 400px) {
          height: 40px;
        }

        h5 {
          text-transform: uppercase;

          @media (max-width: 400px) {
            line-height: 1.8;
          }
        }
      }
    }

    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      text-transform: uppercase;

      &.back {
        justify-content: flex-start;
      }

      .icon {
        font-size: 24px;
        margin-right: 20px;
      }

      .btn-link {
        line-height: 1.7;
        font-weight: 400;
        color: $primary;
        text-transform: none;
      }
    }

    &-selected {
      p {
        color: #9E9E9E;
      }

      &-list {
        &--item {
          display: inline-block;
          background: #C4C4C4;
          padding: 5px 10px;
          border-radius: 50px;
          font-weight: 600;
          color: $white-color;
          margin-right: 5px;
          margin-bottom: 5px;

          .btn-link {
            line-height: 1.7;
            cursor: pointer;
            color: $white-color;
            font-size: 20px;
          }
        }
      }
    }

    &-bottom {
      margin-top: auto;
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px;
      box-shadow: 3px 0px 6px 1px #00000026;
      display: flex;
      justify-content: space-around;

      .btn {
        padding: 0.75rem 1.0rem;

        &-link {
          text-transform: none;
          font-weight: 400;
        }
      }
    }
  }


  .filter.rtl {
    &-choose {    
      &-item {
        &.info {
          p {
            margin-left: 0;
            margin-right: 40px;
          }
        }

        &.has-options {
          &::after {
            right: auto;
            left: 0;
          }
        }

        &-submenu {
          margin-left: 0;
          margin-right: 40px;
        }
      }
    }

    &-head {
      .icon {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    &-selected {
      &-list {
        &--item {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }

</style>